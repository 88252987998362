.sidebar {

  $color1: #ccc;
  $color2: #000;

  .sidebar-menu {
    .ant-menu-submenu {
      border-top: 1px solid #ccc;
      border-radius: 0;

      &:last-child {
        border-bottom: 1px solid #ccc;
      }
    }

    .ant-menu-submenu-title {
      margin: 0;
      width: 100%;
      border-radius: 0;
      padding-top: 25px;
      padding-bottom: 25px;

      &:hover {
        background-color: #fff !important;
      }
    }

    .ant-menu-item {
      background-color: #eee;
      border-top: 1px solid #ddd;
      border-radius: 0;
      margin: 0;
      color: black;
      width: 100%;
      padding-top: 25px;
      padding-bottom: 25px;

      &.stepper-view-menu {
        height: fit-content;
        background-color: #000;
        &:hover {
          background-color: #000 !important;
        }
      }
    }

    .ant-menu-item-selected {
      background-color: #222;
      color: white;
    }

    .ant-menu-item:hover {
      background-color: #ddd !important;
      color: #000 !important;
    }
  }

  .sidebar-chat {
    .ant-menu-item {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px !important;
      height: fit-content;
    }

    .ant-menu-item:hover {
      background-color: #ddd !important;
      color: #000 !important;
    }
  }

  .analysis-steps.ant-steps.ant-steps-vertical {
    .ant-steps-item {
      .ant-steps-item-container {
        .ant-steps-item-tail::after {
          width: 7px;
          height: 100%;
          margin-left: -3px;
          background-image: linear-gradient(to bottom, #{$color1} 100%, #{$color1} 100%);
          transition: background-position 0.3s;
          border-radius: 3px;
        }

        .ant-steps-item-icon {
          svg {
            color: #fff !important;
          }
        }
      }

      &:last-child {
        flex: initial;
      }
    }
  }

  @for $i from 1 through 5 {
    @for $j from 0 through 100 {
      $size: $j + '%';

      .analysis-steps.ant-steps.ant-steps-vertical.percent-#{$j}.step-#{$i} {
        .ant-steps-item {
          .ant-steps-item-container {
            .ant-steps-item-tail::after {
              background-image: linear-gradient(to bottom, #{$color2} #{$size}, #{$color1} #{$size}, #{$color1} 100%);
            }
          }
        }

        @for $k from 0 through $i - 1 {
          .ant-steps-item:nth-child(#{$k}) {
            .ant-steps-item-container {
              .ant-steps-item-tail::after {
                background: #{$color2};
              }
            }
          }
        }

        @for $kk from $i +1 through 5 {
          .ant-steps-item:nth-child(#{$kk}) {
            .ant-steps-item-container {
              .ant-steps-item-tail::after {
                background: #{$color1};
              }
            }
          }
        }
      }
    }
  }

  .stepper-view-menu {
    $color1: #555;
    $color2: #fff;

    .analysis-steps.ant-steps.ant-steps-vertical {
      .ant-steps-item {
        .ant-steps-item-container {
          .ant-steps-item-tail::after {
            background-image: linear-gradient(to bottom, #{$color1} 100%, #{$color1} 100%);
          }

          .ant-steps-item-icon {
            svg {
              color: #000 !important;
            }
          }

          .ant-steps-item-icon {
            background-color: #{$color2};

            .ant-steps-icon {
              color: #000 !important;
            }
          }

          .ant-steps-item-title {
            color: #{$color2} !important;
          }
        }
      }
    }

    @for $i from 1 through 5 {
      @for $j from 0 through 100 {
        $size: $j + '%';

        .analysis-steps.ant-steps.ant-steps-vertical.percent-#{$j}.step-#{$i} {
          .ant-steps-item {
            .ant-steps-item-container {
              .ant-steps-item-tail::after {
                background-image: linear-gradient(to bottom, #{$color2} #{$size}, #{$color1} #{$size}, #{$color1} 100%);
              }
            }
          }

          @for $k from 0 through $i - 1 {
            .ant-steps-item:nth-child(#{$k}) {
              .ant-steps-item-container {
                .ant-steps-item-tail::after {
                  background: #{$color2};
                }
              }
            }
          }

          @for $kk from $i +1 through 5 {
            .ant-steps-item:nth-child(#{$kk}) {
              .ant-steps-item-container {
                .ant-steps-item-tail::after {
                  background: #{$color1};
                }
              }
            }
          }
        }
      }
    }
  }
}